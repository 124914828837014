import { MenuOutlined } from '@ant-design/icons';
import { Feature } from '@shared/features';
import { IUser } from '@shared/types';
import { useFeature } from '@web/common/useFeature';
import { Text } from '@web/components/typography';
import { Dropdown, MenuProps } from 'antd';
import * as React from 'react';

export const AdminUsersRowActionsMenu: React.FC<{
  user: IUser;
  onEditUser: () => void;
  onAssignManager: () => void;
  onDeactivateUser: () => void;
}> = ({ user, onEditUser, onAssignManager, onDeactivateUser }) => {
  const { booleanValue: allowMultipleManagers } = useFeature(
    Feature.ALLOW_MULTIPLE_MANAGERS,
  );

  const items: MenuProps['items'] = [
    {
      key: 'edit-user',
      label: <Text style={{ lineHeight: '40px' }}>Edit user</Text>,
      onClick: onEditUser,
    } as any,
    {
      key: 'assign-manager',
      label: (
        <Text style={{ lineHeight: '40px' }}>
          Assign {allowMultipleManagers ? 'Primary ' : ''}manager
        </Text>
      ),
      onClick: onAssignManager,
    } as any,
    {
      key: 'deactivate-user',
      label: <Text style={{ lineHeight: '40px' }}>Remove user</Text>,
      onClick: onDeactivateUser,
    },
  ].filter((item) => item !== null);

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']}>
        <MenuOutlined />
      </Dropdown>
    </>
  );
};
