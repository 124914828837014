import { GetJiraAccessResponse } from '@shared/jira';
import { get } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useJiraInstallUrl() {
  const url = stringifyUrl({
    url: `/jira/install`,
    query: {},
  });

  return useApi<{ url: string }>(url);
}

export function jiraInstallUrl() {
  const url = stringifyUrl({
    url: `/jira/install`,
    query: {},
  });

  return get<{ url: string }>(url);
}

export function useJiraAccess() {
  const url = stringifyUrl({
    url: `/jira/access`,
    query: {},
  });

  return useApi<GetJiraAccessResponse>(url);
}

export function jiraAccess() {
  const url = stringifyUrl({
    url: `/jira/access`,
    query: {},
  });

  return get<GetJiraAccessResponse>(url);
}
