import { MenuOutlined } from '@ant-design/icons';
import { Feature } from '@shared/features';
import { possessive } from '@shared/possessive';
import { productDetails } from '@shared/reflections';
import {
  IReviewCycle,
  IReviewCycleDashboardRow,
  ShareMode,
} from '@shared/review-cycles';
import { UserRole } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { del, post, put } from '@web/common/api';
import { IS_PRODUCTION } from '@web/common/const';
import { useFeature } from '@web/common/useFeature';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { Text } from '@web/components/typography';
import { ApprovePeersModal } from '@web/notifications/ApprovePeersModal';
import { ApproveReportsModal } from '@web/notifications/ApproveReportsModal';
import { ModifyPeersModal } from '@web/notifications/ModifyPeersModal';
import { Dropdown, MenuProps, message } from 'antd';
import * as React from 'react';

export const DashboardRowActionsMenu: React.FC<{
  row: IReviewCycleDashboardRow;
  onChange: () => void;
  reviewCycle: IReviewCycle;
}> = ({ row, onChange, reviewCycle }) => {
  const { user } = useAuth();
  const { booleanValue: allowReviewCycleExport } = useFeature(
    Feature.ALLOW_REVIEW_CYCLE_EXPORT,
  );
  const { booleanValue: disableCycleRelease } = useFeature(
    Feature.DISABLE_CYCLE_RELEASE,
  );

  const [showApprovePeers, setShowApprovePeers] = React.useState(false);
  const [showApproveReports, setShowApproveReports] = React.useState(false);
  const [showModifyPeers, setShowModifyPeers] = React.useState(false);

  const { isImpersonating, isHrAdmin, isAdmin } = useAuth();
  const { confirm, contextHolder } = useModalConfirm();

  const selfReviewProduct = productDetails(
    reviewCycle.selfReflectionProductName,
  );
  const selfReviewProductName = `self ${selfReviewProduct.lowerCase}`;
  const managerReviewProduct = productDetails(
    reviewCycle.managerReviewProductName,
  );
  const managerReviewProductName = `manager ${managerReviewProduct.lowerCase}`;

  const handleApprovedPeersChanged = () => {
    setShowApprovePeers(false);
    onChange();
  };
  const handleApprovedReportsChanged = () => {
    setShowApproveReports(false);
    onChange();
  };

  const handlePeersModified = () => {
    setShowModifyPeers(false);
    onChange();
  };

  const handleReleaseFeedback = async () => {
    const content = [
      isPeerReviewReleasable(row, reviewCycle) ? 'peer feedback' : undefined,
      isManagerReviewReleasable(row, reviewCycle)
        ? managerReviewProductName
        : undefined,
    ].filter((s) => !!s);
    const releasedContent = content.join(' and ');
    if (
      !(await confirm(
        `Are you sure you want to release ${possessive(
          row.participant.name,
        )} ${releasedContent}?`,
      ))
    ) {
      return;
    }

    try {
      await put(
        `/review-cycles/${reviewCycle.token}/participants/${row.participant.token}/release-feedback`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleSendFeedbackRequests = async () => {
    if (
      !(await confirm(
        `Are you sure you wish to send feedback requests for ${row.participant.name}?`,
      ))
    ) {
      return;
    }

    try {
      await put(
        `/review-cycles/${reviewCycle.token}/participants/${row.participant.token}/send-feedback-requests`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleRemoveParticipant = async () => {
    if (!(await confirm('Are you sure you wish to remove this participant?'))) {
      return;
    }

    try {
      await del(
        `/review-cycles/${reviewCycle.token}/participants/${row.participant.token}`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleMockSelectedPeers = async () => {
    try {
      await post(
        `/peer-reviews-internal/${row.peerReviewToken}/mockPeersSelected`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleMockApprovedPeers = async () => {
    try {
      await post(
        `/peer-reviews-internal/${row.peerReviewToken}/mockPeersApproved`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleApprovePeers = async () => {
    setShowApprovePeers(true);
  };
  const handleModifyPeers = async () => {
    setShowModifyPeers(true);
  };
  const handleApproveReports = async () => {
    setShowApproveReports(true);
  };

  const handleMockPeerFeedback = async () => {
    try {
      await post(
        `/peer-reviews-internal/${row.peerReviewToken}/mockPeerFeedback`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleMockUpwardFeedback = async () => {
    try {
      await post(
        `/upward-reviews-internal/${row.upwardReviewToken}/mockUpwardFeedback`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleMockSelfReview = async () => {
    try {
      await put(
        `/review-cycles/${reviewCycle.token}/participants/${row.participantToken}/mockSelfReview`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleMockManagerReview = async () => {
    try {
      await put(
        `/review-cycles/${reviewCycle.token}/participants/${row.participantToken}/mockManagerReview`,
      );
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const allowPacketExport =
    allowReviewCycleExport &&
    user.role === UserRole.HR_ADMIN &&
    (reviewCycle.managerReflectionCycleEnabled ||
      reviewCycle.selfReflectionCycleEnabled ||
      reviewCycle.peerReviewCycleEnabled);

  const includeMockActions = isImpersonating && !IS_PRODUCTION;
  const items: MenuProps['items'] = [
    !disableCycleRelease
      ? ({
          key: 'release-feedback',
          label: <Text style={{ lineHeight: '40px' }}>Release feedback</Text>,
          disabled: releaseDisabled(row, reviewCycle),
          onClick: handleReleaseFeedback,
        } as any)
      : null,
    isHrAdmin || isAdmin
      ? ({
          key: 'remove-participant',
          label: <Text style={{ lineHeight: '40px' }}>Remove participant</Text>,
          disabled: row.peerRequestsSent > 0,
          onClick: handleRemoveParticipant,
        } as any)
      : null,
    reviewCycle.peerReviewCycleEnabled
      ? {
          key: 'approve-peers',
          label: <Text style={{ lineHeight: '40px' }}>Approve peers</Text>,
          disabled: !row.peerReviewToken || row.peerRequestsSent > 0,
          onClick: handleApprovePeers,
        }
      : null,
    reviewCycle.peerReviewCycleEnabled
      ? {
          key: 'send-feedback-requests',
          label: (
            <Text style={{ lineHeight: '40px' }}>Send feedback requests</Text>
          ),
          disabled:
            !row.peerReviewToken ||
            !reviewCycle.peerReviewFeedbackRequested ||
            row.peerRequestsSent > 0 ||
            !row.peersApproved,
          onClick: handleSendFeedbackRequests,
        }
      : null,
    !reviewCycle.peerReviewCycleEnabled || !isImpersonating
      ? null
      : {
          key: 'modify-peers',
          label: <Text style={{ lineHeight: '40px' }}>Modify peers</Text>,
          disabled: !row.peersApproved,
          onClick: handleModifyPeers,
        },
    !reviewCycle.peerReviewCycleEnabled || !includeMockActions
      ? null
      : {
          key: 'mock-selected-peers',
          label: (
            <Text style={{ lineHeight: '40px' }}>Mock selected peers</Text>
          ),
          disabled: !row.peerReviewToken || row.peerRequestsSent > 0,
          onClick: handleMockSelectedPeers,
        },
    !reviewCycle.peerReviewCycleEnabled || !includeMockActions
      ? null
      : {
          key: 'mock-approved-peers',
          label: (
            <Text style={{ lineHeight: '40px' }}>Mock approved peers</Text>
          ),
          disabled: !row.peerReviewToken || row.peerRequestsSent > 0,
          onClick: handleMockApprovedPeers,
        },
    !reviewCycle.peerReviewCycleEnabled || !includeMockActions
      ? null
      : {
          key: 'mock-feedback',
          label: <Text style={{ lineHeight: '40px' }}>Mock peer feedback</Text>,
          disabled:
            !(row.peersApproved > 0) ||
            !reviewCycle.peerReviewFeedbackRequested ||
            !row.peerRequestsSent,
          onClick: handleMockPeerFeedback,
        },
    reviewCycle.upwardReviewCycleEnabled
      ? {
          key: 'approve-reports',
          label: <Text style={{ lineHeight: '40px' }}>Change reports</Text>,
          disabled: !row.upwardReviewToken || row.upwardRequestsSent > 0,
          onClick: handleApproveReports,
        }
      : null,
    !reviewCycle.upwardReviewCycleEnabled || !includeMockActions
      ? null
      : {
          key: 'mock-upwardfeedback',
          label: (
            <Text style={{ lineHeight: '40px' }}>Mock upward feedback</Text>
          ),
          disabled:
            !(row.approvedReports > 0) ||
            !reviewCycle.upwardReviewFeedbackRequested ||
            !row.upwardRequestsSent,
          onClick: handleMockUpwardFeedback,
        },
    !reviewCycle.selfReflectionCycleEnabled || !includeMockActions
      ? null
      : {
          key: 'mock-self-review',
          label: (
            <Text style={{ lineHeight: '40px' }}>
              Mock {selfReviewProductName}
            </Text>
          ),
          disabled: !row.selfReflectionSent,
          onClick: handleMockSelfReview,
        },
    !reviewCycle.managerReflectionCycleEnabled || !includeMockActions
      ? null
      : {
          key: 'mock-manager-review',
          label: (
            <Text style={{ lineHeight: '40px' }}>
              Mock {managerReviewProductName}
            </Text>
          ),
          disabled: !row.managerReflectionSent,
          onClick: handleMockManagerReview,
        },
    !allowPacketExport
      ? null
      : {
          key: 'preview-packet',
          label: (
            <a
              target="_blank"
              rel="noreferrer"
              href={`${process.env.API_ROOT ?? ''}/api/review-cycles/${
                reviewCycle.token
              }/participants/${row.participantToken}/packet`}
            >
              <Text style={{ lineHeight: '40px' }}>Preview Packet</Text>
            </a>
          ),
          disabled: !allowPacketExport,
        },
    !allowPacketExport
      ? null
      : {
          key: 'export-packet',
          label: (
            <a
              href={`${process.env.API_ROOT ?? ''}/api/review-cycles/${
                reviewCycle.token
              }/participants/${row.participantToken}/packet?download=true`}
            >
              <Text style={{ lineHeight: '40px' }}>Download Packet</Text>
            </a>
          ),
          disabled: !allowPacketExport,
        },
  ].filter((item) => item !== null);

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']}>
        <MenuOutlined />
      </Dropdown>
      {contextHolder}
      {showApprovePeers && (
        <ApprovePeersModal
          organizationToken={reviewCycle.organizationToken}
          peerReviewToken={row.peerReviewToken}
          onSave={handleApprovedPeersChanged}
          onCancel={() => {
            setShowApprovePeers(false);
          }}
          user={row.participant}
        />
      )}
      {showApproveReports && (
        <ApproveReportsModal
          organizationToken={reviewCycle.organizationToken}
          upwardReviewToken={row.upwardReviewToken}
          onSave={handleApprovedReportsChanged}
          onCancel={() => {
            setShowApproveReports(false);
          }}
          user={row.participant}
        />
      )}
      {showModifyPeers && (
        <ModifyPeersModal
          organizationToken={reviewCycle.organizationToken}
          peerReviewToken={row.peerReviewToken}
          onSave={handlePeersModified}
          onCancel={() => {
            setShowModifyPeers(false);
          }}
          user={row.participant}
        />
      )}
    </>
  );
};

const releaseDisabled = (
  row: IReviewCycleDashboardRow,
  reviewCycle: IReviewCycle,
): boolean => {
  if (isManagerReviewReleasable(row, reviewCycle)) {
    return false;
  }
  if (isPeerReviewReleasable(row, reviewCycle)) {
    return false;
  }

  return true;
};

const isManagerReviewReleasable = (
  row: IReviewCycleDashboardRow,
  reviewCycle: IReviewCycle,
): boolean => {
  return (
    reviewCycle.managerReflectionCycleStarted &&
    row.managerReflectionCompleted &&
    !row.managerReviewReleased
  );
};

const isPeerReviewReleasable = (
  row: IReviewCycleDashboardRow,
  reviewCycle: IReviewCycle,
): boolean => {
  return (
    reviewCycle.peerReviewCycleEnabled &&
    reviewCycle.peerReviewShareMode === ShareMode.SHARED &&
    reviewCycle.peerReviewFeedbackRequested &&
    row.peerRequestsSent > 0 &&
    !row.feedbackReleased
  );
};
