import { PlusOutlined } from '@ant-design/icons';
import {
  addBedrockChannelsInternal,
  deleteBedrockChannelInternal,
  deleteEnrolledBedrockUserInternal,
  enrollBedrockUsersInternal,
  refreshBedrockEligibleChannnelsInternal,
  runBedrock,
  updateBedrockEnrollmentInternal,
  useBedrockConfigurationInternal,
  useEnrolledBedrockUsersInternal,
} from '@client/BedrockConfigurationClient';
import { update as updateUser } from '@client/UsersClient';
import {
  BedrockEnrollmentUpdate,
  IBedrockEnrollment,
  RunBedrockRequest,
} from '@shared/bedrock';
import { Feature } from '@shared/features';
import { OrganizationToken, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useOrganizationFeature } from '@web/common/useOrganizationFeature';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header2 } from '@web/components/typography';
import {
  Button,
  Descriptions,
  DescriptionsProps,
  Skeleton,
  message,
} from 'antd';
import { isEmpty } from 'lodash';
import pluralize from 'pluralize';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { EnrollUsersModal } from '../EnrollUsersModal';
import { EnrollmentsTable } from '../EnrollmentsTable';
import { AddChannelsButton } from './AddChannelsButton';
import { BedrockTabs } from './BedrockTabs';
import { SetupRunButton } from './SetupRunButton';
import { SetupRunModal } from './SetupRunModal';
import { ShowChannelsButton } from './ShowChannelsButton';

export const InternalBedrockPage: React.FC = () => {
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();
  const { data: response, mutate: reloadConfiguration } =
    useBedrockConfigurationInternal(organizationToken);
  const { data: enrollments, mutate: reloadEnrollments } =
    useEnrolledBedrockUsersInternal(organizationToken);
  const { booleanValue: useNewModal } = useOrganizationFeature(
    organizationToken,
    Feature.BEDROCK_USE_DB_FOR_CHANNELS,
  );
  const [showEnrollModal, setShowEnrollModal] = React.useState(false);
  const [showSetupRunModal, setShowSetupRunModal] = React.useState(false);

  if (!response || !enrollments) {
    return (
      <PageContent>
        <PageHeader title="Bedrock" />
        <BedrockTabs />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  const handleEnroll = async (userToken: UserToken) => {
    try {
      await enrollBedrockUsersInternal(organizationToken, userToken);
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to enroll user');
    } finally {
      setShowEnrollModal(false);
    }
  };

  const handleDelete = async (enrollment: IBedrockEnrollment) => {
    try {
      await deleteEnrolledBedrockUserInternal(
        enrollment.user.token,
        organizationToken,
      );
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to remove user');
    }
  };

  const handleAddChannels = async (channelIds: string[]) => {
    try {
      await addBedrockChannelsInternal(organizationToken, channelIds);
      await reloadConfiguration();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to add channels');
    }
  };

  const handleRefreshChannels = async () => {
    try {
      await refreshBedrockEligibleChannnelsInternal(organizationToken);
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to schedule channel refresh');
    }
  };

  const handleDeleteChannel = async (channelId: string) => {
    try {
      await deleteBedrockChannelInternal(organizationToken, channelId);
      await reloadConfiguration();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to remove channel');
    }
  };

  const handleRun = async (data: RunBedrockRequest) => {
    await runBedrock(organizationToken, data);
    void message.success('Scheduled');
    setShowSetupRunModal(false);
  };

  const handleEdit = async (
    userToken: UserToken,
    updates: BedrockEnrollmentUpdate,
  ) => {
    try {
      await updateBedrockEnrollmentInternal(
        organizationToken,
        userToken,
        updates,
      );
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to update');
    }
  };

  const items: DescriptionsProps['items'] = [
    {
      key: 'channelCount',
      label: 'Enrolled Channels',
      children: (
        <ShowChannelsButton
          organizationToken={organizationToken}
          onDelete={handleDeleteChannel}
        >
          View {response.enrolledChannelCount}{' '}
          {pluralize('channel', response.enrolledChannelCount)}
        </ShowChannelsButton>
      ),
    },
  ];

  return (
    <PageContent>
      <PageHeader title="Scribe AI" />
      <BedrockTabs />
      <Pane>
        <Column gap={24}>
          <Column gap={12}>
            <Row>
              <Header2>Configuration</Header2>
              <GrowingSpacer />
              <Row gap={6}>
                <SetupRunButton
                  userOptions={enrollments.map((enrollment) => enrollment.user)}
                  onRun={handleRun}
                />
                <AddChannelsButton
                  organizationToken={organizationToken}
                  useNewModal={useNewModal}
                  onAddChannels={handleAddChannels}
                  onRefreshChannelList={handleRefreshChannels}
                />
              </Row>
            </Row>
            <Descriptions items={items} />
          </Column>
          <Column gap={12}>
            <Row>
              <Header2>
                Enrollment (Max: {response.configuration.enrollmentLimit})
              </Header2>
              <GrowingSpacer />
              <Button
                type="primary"
                onClick={() => {
                  setShowEnrollModal(true);
                }}
                style={{ maxWidth: 200 }}
              >
                <PlusOutlined /> Enroll Users
              </Button>
            </Row>
            {enrollments ? (
              <EnrollmentsTable
                enrollments={enrollments}
                showJira
                showLinear
                onDelete={handleDelete}
                onEditGithub={async (
                  userToken: UserToken,
                  githubLogin: string,
                ) => {
                  await updateUser(userToken, {
                    githubLogin: isEmpty(githubLogin)
                      ? null
                      : githubLogin.trim(),
                  });
                }}
                onEdit={handleEdit}
              />
            ) : (
              <Skeleton />
            )}
          </Column>
        </Column>
      </Pane>
      {showEnrollModal && (
        <EnrollUsersModal
          organizationToken={organizationToken}
          omitUserTokens={enrollments?.map(
            (enrollment) => enrollment.user.token,
          )}
          onCancel={() => {
            setShowEnrollModal(false);
          }}
          onEnroll={handleEnroll}
        />
      )}
      <SetupRunModal
        open={showSetupRunModal}
        onCancel={() => {
          setShowSetupRunModal(false);
        }}
        onSubmit={handleRun}
        userOptions={enrollments.map((enrollment) => enrollment.user)}
      />
    </PageContent>
  );
};
