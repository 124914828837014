import { assignManager } from '@client/TeamClient';
import {
  IUser,
  OrganizationToken,
  UserMapItem,
  UserToken,
} from '@shared/types';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectUser } from '@web/components/users/SelectUser';
import { Modal, message } from 'antd';
import * as React from 'react';
import { useState } from 'react';

export const SelectManagerModal: React.FC<{
  organizationToken: OrganizationToken;
  user: IUser;
  managers: UserMapItem[];
  omitUserTokens: UserToken[];
  onCancel: () => void;
  onSave: (managerToken: UserToken) => void;
}> = ({
  organizationToken,
  user,
  managers,
  omitUserTokens,
  onCancel,
  onSave,
}) => {
  const [managerToken, setManagerToken] = useState<UserToken | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleOk = async () => {
    setIsSaving(true);

    try {
      await assignManager(user.token, managerToken);
      void message.success('Success');
      onSave(managerToken);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  const handleManagerSelected = (managerToken: UserToken) => {
    setManagerToken(managerToken);
  };

  return (
    <Modal
      title="Assign Manager"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
      okText="Assign"
    >
      <Column gap={12}>
        <Text>Select a manager for {user.name}.</Text>
        <SelectUser
          style={{ width: '100%' }}
          organizationToken={organizationToken}
          initialUser={managers ? managers[0] : null}
          onChange={handleManagerSelected}
          omitUserTokens={omitUserTokens}
          allowClear={true}
        />
      </Column>
    </Modal>
  );
};
